import * as React from "react"
import styled from "styled-components"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"
import { graphql } from "gatsby"
import { H1, H4 } from "../components/shared/Typography/Typography"
import { StaticImage } from "gatsby-plugin-image"
import { ButtonInternalLink } from "../components/shared/Link/Link"
import { device } from "../components/shared/styles/breakpoints"
import { ArrowLeftIcon } from "../components/shared/Icons/ArrowLeftIcon"
import Bg from "../images/404/404.svg"
import { useTranslation } from "gatsby-plugin-react-i18next"

const StyledSection = styled.div`
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  padding: ${({ theme }) => `0 ${theme.spaces[16]}`};
`
const StyledIconWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces[64]};

  @media ${device.lg} {
    margin-bottom: ${({ theme }) => theme.spaces[26]};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 861px;
    height: 336px;
    background-image: url(${Bg});
    background-repeat: no-repeat;
    background-size: 860px 335px;
  }
`
const StyledTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const StyledTitle = styled(H1)`
  color: ${({ theme }) => theme.colors.red};
  margin: 0;
`
const StyledText = styled(H4)`
  margin: ${({ theme }) => `${theme.spaces[8]} 0 ${theme.spaces[40]}`};
  max-width: 400px;
`

export default function NotFoundPage () {
  const { t } = useTranslation()
  return (
    <Layout is404={true}>
      <Seo title="404: Not found" />
      <StyledSection>
        <StyledIconWrapper>
          <StaticImage src="../images/404/404.png" alt="not found" />
        </StyledIconWrapper>
        <StyledTextWrapper>
          <StyledTitle>Oops!</StyledTitle>
          <StyledText>{t("404")}</StyledText>
          <ButtonInternalLink color="primary" to="/" prefixIcon>
            {t("homepage")}
          </ButtonInternalLink>
        </StyledTextWrapper>
      </StyledSection>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
